<template>
  <section class="projects fade d-flex flex-column">
    <div class="bslIcons">
      <div class="a"></div>
      <div class="b"></div>
      <div class="c"></div>
    </div>
    <div class="create-project-wrapper">
      <div class="create-project__header mb-6">
        <div class="create-project__title">Создать проект</div>
        <v-btn class="create-project__close" icon plain @click="$router.back()">
          <v-icon color="black" size="20">mdi-close</v-icon>
        </v-btn>
      </div>
      <div class="project-info">
        <v-form ref="form" v-model="valid" class="col-md-10 col-xl-8 mt-8" lazy-validation>
          <div class="d-flex justify-space-between mb-4">
            <div class="col-item">
              <v-text-field
                v-model="project.title"
                required
                :rules="nameRules"
                label="Название проекта"
                class="rounded-lg"
                hide-details
                background-color="white"
                outlined />
            </div>

            <div class="col-item">
              <v-select
                :items="getFilterPms"
                required
                :rules="nameRules"
                v-model="project.manager"
                item-text="value"
                item-value="id"
                label="Менеджер"
                hide-details
                outlined
                background-color="white"
                class="rounded-lg" />
            </div>

            <div class="col-item">
              <v-text-field
                v-model="project.code"
                label="Ключ проекта в Jira"
                class="rounded-lg"
                hide-details
                @input="onInputProjectCode"
                background-color="white"
                outlined
                :error="projectCodeIsError" />
            </div>
          </div>
          <div class="d-flex justify-space-between mb-4">

            <div class="col-item">
              <DatePicker
                placeholder="Начало проекта"
                :value="project.date_start"
                @changeDate="changeDateFirst"
              />
            </div>

            <div class="col-item">
              <DatePicker
                placeholder="Окончание проекта"
                :value="project.date_end"
                @changeDate="changeDateSecond"
                :isRequired="false"
              />
            </div>

            <div class="col-item">
              <v-select
                :items="status"
                required
                :rules="nameRules"
                v-model="project.status"
                label="Статус"
                outlined
                hide-details
                background-color="white"
                class="rounded-lg" />
            </div>
          </div>
          <div class="d-flex justify-space-between">
            <v-textarea
              outlined
              v-model="project.description"
              label="Описание"
              required
              :rules="nameRules"
              hide-details
              class="rounded-lg"
              background-color="white"
              no-resize
              height="135" />
          </div>
        </v-form>
      </div>
    </div>

    <v-form
      ref="formSpec"
      lazy-validation
      v-model="validSpec"
      class="mt-8"
    >
      <div class="specialist-rate">
        <div class="specialist-rate__resource_type mb-4">Ставки специалистов</div>
        <div class="specialist-rate__content d-flex flex-column justify-space-between">
          <div class="specialist-rate__inputs">
            <v-col
              cols="auto"
              v-for="(specialist, index) in project.specialists"
              :key="index"
            >
              <v-row class="d-flex align-center">
                <v-select
                  class="position rounded-lg mr-4"
                  outlined
                  label="Должность"
                  item-text="value"
                  item-value="id"
                  :items="getSpecialists(specialist.id)"
                  hide-details
                  v-model="specialist.id"
                  :rules="nameRules"
                  required
                ></v-select>

                <PayTime
                  @changePayTime="changePayTime"
                  :value="specialist.payment"
                  :index="index"
                />

                <v-btn
                  v-if="project.specialists.length !== 1"
                  icon
                  class="ml-4"
                  @click="deleteRate(index)"
                >
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </v-row>
            </v-col>
          </div>
          <v-btn
            outlined
            class="btn-add mt-3 radius-root"
            height="52"
            width="234"
            @click="addRate"
          >+ Добавить сотрудника</v-btn>
        </div>
      </div>

    </v-form>
    <v-btn
      class="white--text btn-create radius-root"
      width="210"
      color="black"
      height="52"
      :disabled="!valid || !validSpec"
      @click="submit()"
    >
      Создать
    </v-btn>
  </section>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import debounce from 'lodash/debounce';
import { statuses } from '@/core/constants/statuses';
import notification from '@/mixins/notification';
import PayTime from '@/components/Inputs/PayTime.vue';
import DatePicker from '@/components/UI/DatePicker.vue';

export default {
  name: 'CreateProject',
  mixins: [notification],
  components: {
    PayTime,
    DatePicker,
  },
  data() {
    return {
      valid: false,
      validSpec: false,
      project: {
        title: '',
        description: '',
        status: '',
        manager: '',
        code: '',
        budget: '',
        date_start: '',
        date_end: '',
        specialists: [{ id: 9, payment: '' }],
      },

      status: statuses,

      projectCodeIsError: false,
    };
  },

  computed: {
    ...mapState(['filters']),

    getFilterPms() {
      return this.filters?.pms || [];
    },

    formattedDateStart: {
      get() {
        return this.getFormattedDate(this.project.date_start);
      },
      set(val) {
        console.log(val);
      },
    },
    formattedDateFinish: {
      get() {
        return this.getFormattedDate(this.project.date_end);
      },
      set(val) {
        console.log(val);
      },
    },
  },
  methods: {
    ...mapActions('projects', ['createProject', 'getProjectFromJIRA']),
    ...mapActions(['getFilters']),

    changeDateFirst(val) {
      this.project.date_start = val;
    },

    changeDateSecond(val) {
      this.project.date_end = val;
    },

    getSpecialists(id) {
      if (this.filters?.positions) {
        return this.filters.positions.filter((spec) => !this.project.specialists.some((proj) => proj.id === spec.id && proj.id !== id));
      }
      return [];
    },

    changeBudget(val) {
      this.project.budget = val;
    },
    changePayTime(val) {
      this.project.specialists[val.index].payment = val.payment;
    },
    onInputProjectCode() {
      if (this.project.code) {
        this.projectCodeIsError = true;
        this.debounceFetchProjectFromJIRA();
      } else {
        this.projectCodeIsError = false;
      }
    },
    fetchProjectFromJIRA() {
      if (!this.project.code) {
        this.projectCodeIsError = false;
        return;
      }
      this.getProjectFromJIRA(this.project.code)
        .then(({ data }) => {
          this.project.image_url = data.avatar;
          this.project.title = data.name;
          this.projectCodeIsError = false;
        })
        .catch(() => {
          this.projectCodeIsError = true;
        });
    },

    debounceFetchProjectFromJIRA: debounce(function () {
      this.fetchProjectFromJIRA();
    }, 300),

    checkValid() {
      this.$refs.form.validate();
      this.$refs.formSpec.validate();

      return new Promise((resolve) => {
        this.isDatesPeriodValid(this.project.date_start, this.project.date_end, () => {
          this.project.date_end = '';
          this.showErrorNotification('Дата окончания проекта введена некорректно');
          resolve(false);
        }, () => {
          if (this.project.date_end === '') this.project.date_end = null;
          resolve(!!(this.$refs.form.validate() && this.$refs.formSpec.validate()));
        });
      });
    },

    async submit() {
      await this.fetchProjectFromJIRA();
      if (await this.checkValid()) {
        const { data } = await this.createProject({
          ...this.project,
          specialists: this.project.specialists
            .filter((el) => el.payment && el.id)
            .map((el) => ({
              payment: Number(el.payment),
              resource_kind: el.id,
            })),
        });
        if (data.non_field_errors === undefined) {
          this.$router.push(`/projects/${data.id}`);
        } else {
          this.showErrorNotification('Невозможно создать проект на другого PM');
        }
      }
    },

    addRate() {
      this.project.specialists.push({ id: null, payment: '' });
    },
    deleteRate(index) {
      this.project.specialists.splice(index, 1);
    },
  },
  async created() {
    await this.getFilters();
  },
};
</script>
<style lang="scss" scoped>
@import "src/assets/styles/bslIcons";

::v-deep .v-text-field fieldset {
  color: #d6d6d6 !important;
}

.btn-create {
  align-self: center;
}

.create-project-wrapper {
  margin-bottom: 40px;
}

.create-project__header {
  display: flex;
  align-items: center;
}

.create-project__title {
  margin-left: 32px;
  font-weight: bold;
  font-size: 28px;
  line-height: 34px;
}

.create-project__close {
  margin-left: auto;
  margin-right: 10px;
}

.project-info {
  border: 1px solid #f1f1f1;
  border-radius: 32px;
  height: 359px;
  background: #f9f9f9;
  & > * {
    padding: 0 32px;
  }
}

.col-item {
  display: flex;
  width: 33%;
}

.modal__sub-title {
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  margin-bottom: 24px;
}

.staff-list {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.staff-list__item {
  width: 49%;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  & .v-input {
    max-width: 190px;
  }
}

.specialist-rate {
  padding: 0 32px;
  .v-select {
    width: 220px;
    max-width: 220px;
  }
}

.specialist-rate__inputs {
  display: flex;
  flex-wrap: wrap;

  & > * {
    margin: 0 30px 20px 0px;
  }
}
</style>
